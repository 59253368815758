<template>
  <div>
    <!-- 成员管理 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
          <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="getTimeRange($event,'startDate','endDate','queryParams')" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
        </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.content" clearable placeholder="请输入商户名称" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="content-wrap">
      <div class="btn-wrap cfx">
      </div>
      <div v-if="dealOrderTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
     <paged-table
      v-if="dealOrderTotal>0"
      :data="dealOrderList"
      :total="dealOrderTotal"
      :loading="tLoading"
      :isShowSelection="false"
      defaultSortProp="id"
      v-model="queryParams"
      @refresh="refresh"
    >
          <el-table-column prop="extend.userName" label="用户" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="account" label="学号" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="extend.schoolName" label="学校" :show-overflow-tooltip="true" > </el-table-column>
          <el-table-column prop="orderType" label="类型" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <span v-if="scope.row.orderType == 1">套餐充值</span>
            <span v-if="scope.row.orderType == 2">套餐续订</span>
            <span v-if="scope.row.orderType == 3">套餐升级</span>
            <span v-if="scope.row.orderType == 4">套餐报停</span>
            <span v-if="scope.row.orderType == 5">套餐复通</span>
            <span v-if="scope.row.orderType == 6">套餐变更</span>
            <span v-if="scope.row.orderType == 7">手机号购买</span>
          </template>
          </el-table-column>
          <el-table-column prop="content" label="商品" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="orderStatus" label="状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus == 0">等待支付</span>
            <span v-if="scope.row.orderStatus == 1">交易成功</span>
            <span v-if="scope.row.orderStatus == 2">已退款</span>
            <span v-if="scope.row.orderStatus == 4">交易关闭</span>
          </template>
          </el-table-column>
          <el-table-column prop="orderNum" label="订单号" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="oriPrice" label="价格（单位：元）" :show-overflow-tooltip="true" >
          </el-table-column>
          <el-table-column prop="payPrice" label="实付" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="payType" label="支付方式" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.payType == 1">微信支付</span>
            <span v-if="scope.row.payType == 2">支付宝</span>
            <span v-if="scope.row.payType == 3">余额支付</span>
            <span v-if="scope.row.payType == 4">线下支付</span>
          </template>
          </el-table-column>
          <el-table-column prop="payTime" label="支付时间" :show-overflow-tooltip="true"></el-table-column>
        </paged-table>
    </div>

  
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import datePicker from "@/mixins/datePicker";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'dealOrder',
  components: {
    PagedTable,
  },
  mixins: [pageMixin, provinces,datePicker],
  data() {
    return {
      queryParams: {
        name: null,
        startDate: '',
        endDate: '',
        content:'',
        hallType:1,
      },
      userRoleIds:[],
      roleOptions:[],
      teamRoles:[],
      
      pageApi:'getDealOrderList',
     
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("dealOrder", ["dealOrderList", "dealOrderTotal"]),
  },
  methods: {
    ...mapActions("dealOrder",[ "getDealOrderList", "payAlipayRefund", "payWxpayRefund", "payYepayRefund"]),

 
  },
  async mounted() {
    
  }
};
</script>